#gallery {
    position: flex;
    flex-direction: column;
}

#gallery-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin: 2rem;
}

#gallery-title {
    align-self: center;
    font-size: 2.6rem;
}

.gallery-row {
    display: flex;
    align-self: center;
    column-gap: 1rem;
}

.gallery-image {
    width: 20rem;
    height: 20rem;
    object-fit: cover;
    transition: all 0.4s;
}

.gallery-image:hover {
    scale: 1.05;
    transition: all 0.4s;
}

.filter {
    background-color: black;
    overflow: hidden;
    height: 19.9rem;   
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.card-text {
    background: linear-gradient(rgba(0,0,0,0) 0%,rgba(0,0,0,.7) 20%);
    padding: .6rem;
    color: white;
    width: 100%;
    height: 3rem;
    transition: all 0.4s;
    text-align: center;
    font-weight: bold;
    font-size: large;
}

#image-section {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

#gallery-description {
    display: none;
    padding: 4rem;
    transition: all 1s;
    opacity: 0.3;
    column-gap: 2rem;
}

.description-image {
    height: 20rem;
    border-radius: 1rem;
    box-shadow: 3px 3px 12px rgb(41, 41, 41);
    object-fit:cover;
}

#description-text-field {
    width: 50vw;
    height: 30%;
    margin: 2rem;
    padding: 3rem;
    border-radius: 1rem;
    justify-self: center;
    font-size: x-large;
    box-shadow: 2px 2px 10px rgb(45, 45, 45);
}

#description-header {
    text-align: center;
    margin-bottom: 2rem;
}

#description-text {
    text-indent: 3rem;
}


@media only Screen and (max-width: 48em){
    .gallery-row {
        flex-direction: column;
        row-gap: 2rem;
    }
    
    .card-text {
        position: relative;
        top: -3rem;
    }

    #gallery-description {
        flex-direction: column;
        align-items: center;
        padding: 2rem;
    }

    .description-image {
        width: 80vw;
    }

    #description-text-field {
        width: 100vw;
        padding: 1rem;
        border-radius: 0;
        margin-bottom: 0;
        border: none;
        box-shadow: none;
    }

    #description-header {
        width: 100vw;
    }

    #description-text {
        text-indent: 1rem;
    }
}