#zipper-container {
  background-image: url("../Images/stripes.webp");
  margin: 2rem 0;
  width: 100%;
  mask-image: linear-gradient(0deg, rgba(0,0,0,0) 0%,  rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%,  rgba(0,0,0,0) 100%);  
}

#zipper-background {
  display: flex;
  justify-content: flex-end;
  background: linear-gradient(rgba(141, 141, 141, 0) 0%, rgba(141, 141, 141, .2) 50%);
}

#zipper-elements {
  display: flex;
  align-items: center;
  column-gap: 3rem;
  width: 65vw;
  margin: 2.5rem 1rem;
}

#field {
  font-size: 2rem;
  padding: .5rem 1rem;
  border-radius: 8px;
  width: 8.4rem;
  font-family:Monospace;
}

#question, #answer {
  font-size: 2rem;
  font-weight: 700;
}

@media only Screen and (max-width: 48em) {
  #zipper-elements {
    row-gap: 1rem;
    width: 100vw;
    text-align: center;
    flex-direction: column;
    min-height: 25vh;
  }
}