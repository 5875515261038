#about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--peach);
}

#home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--peach);
}

#not-found {
  display: flex;
  justify-content: center;
}

#image-not-found {
  width: 50vw;
}

#request {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--peach);
}

#request iframe {
    border: none;
    width: 90vw;
    height: 40vh;
}

#services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--peach);
}

#terms-of-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only Screen and (max-width: 48em){

}