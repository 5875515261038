:root{
    --orange:#FFC436;
    --deep-blue:#0C356A;
    --light-blue:#0174BE;
    --green:#4A8271;
    --peach:#fffefb;
    --light-grey:#cecece;
}

* {
    margin:0;
    padding:0;
    box-sizing:border-box;
    font-family: sans-serif;
    line-height: 1.5;
}
html {
    scroll-behavior:smooth;
    overflow-x: hidden;
}
body {
    overflow-x: hidden;}
a {
    text-decoration: none;
}
