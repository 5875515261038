#highend-gallery-container {
    background-color: black;
}
#first-section-background {
    background-image: url("../Images/sub-zero-kitchen.webp");
    background-size:cover;
    background-repeat: no-repeat;
    background-position:0;
    position: relative;
    transition-delay: .5s;
    width: 100vw;
    height: 92vh;
    border-bottom: solid 5px black;
}

#first-section-text {
    position: absolute;
    width:fit-content;
    bottom: 65%;
    left: 20%;
    font-size: 5vh;
    font-weight: 600;
    color: white;
    opacity: 0;
    transition: all 1.5s ease;
}


#first-section-shade {
    background: rgb(0, 0, 0);
    transition: all 1.5s ease;
    width: 100%;
    height: 92vh;
    opacity: 0;
}

#types {
    display: flex;
    position: relative;
    transition: all 0.6s;
    transition-delay: 0.6s;
    bottom: 6.5rem;
    user-select: none;
    z-index: 1;
}

#types-container {
    overflow: hidden;
}

.image-btn {
    width: 25%;
    display: flex;
    text-align: center;
    border-bottom: solid 5px black;
    border-right: solid 5px black;
    color: white;
    font-size: 1.8rem;
    font-weight: 500;
    background-size: cover;
    background-position: 0 38%;
    cursor: pointer; 
    
}

.image-btn-shade {
    background: rgba(0, 0, 0, 0.721);
    width: 100%;
    height: 100%;
    padding: 1rem;
    overflow: hidden;
    transition: all 0.05s;
    
}

.image-btn-shade:active {
    background: rgba(0, 0, 0, 0.45);
}

#btn1 {
    background-image: url("../Images/appliances/subzero-fridge.webp");
}

#btn2 {
    background-image: url("../Images/appliances/viking-fridge.webp");
}

#btn3 {
    background-image: url("../Images/appliances/monogram-fridge.webp");
}

#btn4 {
    background-image: url("../Images/appliances/liebherr-fridge.webp");
}

@media only Screen and (max-width: 48em){
    #first-section-background {
        background-position:40%;
        height: 66vh;
    }
    
    #first-section-shade {
        height: 66vh;
    }

    #types {
        flex-direction: column;
        height: auto;
        bottom: 21rem;
    }

    .image-btn {
        width: 100%;
    }

    .image-btn-shade {
        padding: 1.7vh;
    }

    #first-section-text {
        font-size: 2.2rem;
        position: absolute;
        height: fit-content;
        padding: 1rem;
        top: 5vh;
        left: 10%;
        width: 80%;
        backdrop-filter: blur(1px);
    }
}