
#back-image {
  display:flex;
  background-image: url("../Images/bosch-kitchen-appliances.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  justify-content: right;
  width: 100vw;
  height:90vh;
}

#shade {
  display:flex;
  position:relative;
  object-fit: none;
  height: 100%;
  width: 100%;
  background: linear-gradient(-60deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 53%, rgba(0,0,0,0) 80%);
  align-items: center;
  padding: 3rem;
}

.text-main {
  font-size: 4vw;
}

#text {
  position:absolute;
  font-weight: 600;
  color: white;
  text-align: right;
  width: 60%;
  right: 8%;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
  @keyframes scale {
    100% {
      transform: scale(1);
    }
  }
  
#text span {
  opacity: 0;
  filter: blur(4px);
}
  
#text span:nth-child(1) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

#text span:nth-child(3) {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

#text span:nth-child(5) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

@media only Screen and (max-width: 48em) { 
  #back-image {
    height: 50vh;
    background-position-x: -80px;
  }

  #shade {
    background: rgba(0,0,0,0.6);
    transform: skew(0);
    width: 100%;
    right: 0;
  }

  #text {
    text-align: center;
    width: 100%;
    left: 0;
    font-size: 1.8rem;
    top: 20%;
  }

  #text span {
    font-size: 2rem;
  }
}