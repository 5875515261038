#menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.3% 0;
  column-gap: 3rem;
  background-color: var(--deep-blue);
  color: white;
  width:100vw;
  z-index: 10;
  max-height: 12vh;
}

#link-logo {
  margin-left: 10vw;
}

.logo-link {
  display: flex;
  align-items: center;
  padding: 0.4rem;
  object-fit: contain;
  cursor:pointer;
  height: 10vh;
}

#menu-buttons {
  display: flex;
  padding-right: 5%;
  min-width: 15vw;
  align-items: center;
  align-content: space-between;
  flex-direction: row;
  column-gap: 2vw;
}

#menu-buttons a{
  font-weight: 600;
  font-size: calc(1.5vh + 0.8rem);
  color: var(--white);
  line-height: 1.5;
}
  
#menu-buttons a::after{
  content:"";
  display: block;
  height: 3px;
  width: 3px;
  background: transparent;
  transition: width 0.8s;
}

#menu-buttons a:not(:last-child):hover::after{
  width:100%;
  background:#41D0FF;
}

.btn-bright {
  background-color: var(--orange);
  padding: 0.3rem 1rem;
  font-size: calc(1.5vh + 0.8rem);
  border-radius: 20px;
  border: 2px solid black;
  color: white;
  text-shadow: 0 0 2px black, 0 0 2px black;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-bright:hover{
  transform: scale(0.95);
  transition: all 0.2s;
}

#img-menu-opener {
  position: relative;
  background-color: transparent;
  width:2rem;
  height: 2rem;
  cursor: pointer;
  display: none;
  transition: all 0.1s;
}

#menu-mobile {
  display: none;
}

@media only Screen and (max-width: 48em) {
  #link-logo {
    margin-left: 0;
  }
  
  .logo-link {
    width: auto;
    padding: 0.8rem;
    margin-left: 0;
  }

  #menu {
    height: 10vh;
    justify-content: center;
    column-gap: 3rem;
  }
  
  #menu-buttons {
    display: none;
  }

  #img-menu-opener {
    display: inline-block;
    width: 6vh;
    height: 6vh;
  }

  #menu-mobile {
    position: absolute;
    display:block;
    top:0;
    left:0;
    visibility:hidden;
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
  }
  
  #menu-mobile a{
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
    padding: 1.2rem;
    width: 100%;
    border-bottom: solid 2px var(--light-blue);
  }

  #menu-mobile-buttons {
    display:flex;
    flex-direction: column;
    align-items:flex-start  ;
    padding: .5rem 1rem;
    padding-bottom: 15vh;
    position: relative;
    top: 10vh;
    height: 90vh;
    background: var(--deep-blue);
    width: 80vw;
    right: 80vw;
    overflow: hidden;
    overflow-y: auto;
    touch-action: pan-y;
    transition: all .3s;
    z-index: 1;
  }
}