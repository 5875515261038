#form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 42rem;
  align-self: center;
  margin: 5vh;
  padding: 4rem;
  background-color: var(--deep-blue);
  border: 1px solid black;
  border-radius: 16px;
  box-shadow: 15px 15px 15px black;
}

#form-title {
  display: inline-block;
  font-size: calc(1rem + 2vw);
  position: relative;
  color: white;
  text-shadow: 0 0 5px black, 0 0 5px black;
}

#request-form {
  display:flex;
  flex-direction: column;
  padding:2rem;
  font-size: 20px;
}

#request-form input{
  padding: .5rem calc(0.5rem + 1vw);
  background-color: white;
  border:none;
  border-radius: 3px;
  font-size: 22px;
  margin-bottom: 2rem;

}

#request-form textarea {
  padding: 1rem calc(0.5rem + 1vw);
  background-color: white;
  border:none;
  border-radius: 3px;
  font-size: 22px;
  margin-bottom: 2rem;
}

#request-form textarea:active, #request-form textarea:focus {
  border:none;
  background-color: #CACACA
}

#request-form label{
  color: white;
  margin-left: 1rem;
  margin-bottom: -1.5rem;
  font-weight: 500;
}

#request-form button {
  padding: .2rem;
  background-color: var(--orange);
  color: black;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid black;
  font-size: 1.8rem;
  transition: all 0.2s;
}
#request-form button:hover{
  transform: scale(0.95);
}

.profiles {
  display: flex;
  column-gap: 2rem;
  margin-top: 2rem;
  align-items: flex-end;
}

.profiles a:not(:last-child) {
  margin-right: 2rem;
}

.profiles img {
  width: 5rem;
  column-gap: 3rem;
  height: auto;
}

.profiles img:hover {
  filter: sepia(30%) saturate(500%) 
}

@media only Screen and (max-width: 48em) {

  #form-container {
    width: 83vw;
    margin: 2rem;
  }
  
  #form-title {
    font-size: 2.5rem;
    width:150px
  }
  
  #request-form {
    align-items: center;
  }
  
  #request-form label{
    font-size: x-large;
    text-align: center;
  }

  #request-form input {
    width: 100%;
  }

  #request-form textarea {
    width: 70vw;
  }
  
  #request-form input:active, #request-form input:focus {
    border:none;
    background-color: #CACACA
  }

  #request-form button {
    width: 90%;
  }
  
  #request-form button:hover{
    transform: scale(1.05);
  }

  #request-form button:active{
    transform: scale(0.95);
  }
}
