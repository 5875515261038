.cities-image {
    background: url("../Images/Appliance-repair-work-car.webp");
    background-position-y: 70%;
    background-size: cover;
    width: 100vw;
    margin-top: 2rem;
}

.cities-box {
    display:flex;
    background-color: rgba(0, 27, 66, 0.75);
    padding: 4rem;
    flex-direction: column;
    color: white;
    font-size: 1.6rem;
    font-weight: bold;
    width: 100vw;
  }

.cities-table {
    display:flex;
    flex-direction: row;
    align-self: center;
    column-gap: 7rem;
}

.city-table-header {
    text-align: center;
    margin-bottom: 2rem;
}

.cities-column {
    display:flex;
    flex-direction: column;
}

.cities-column a {
    width: max-content;
    color: white;
}

.cities-column a:hover{
    cursor: pointer;
    color: var(--light-blue);
}

.cities-column a::after{
    content:"";
    display: block;
    height: 3px;
    width: 3px;
    background: transparent;
    transition: width 0.8s;
  }
  
.cities-column a:hover::after{
    width: 100%;
    background: var(--light-blue);
  }


@media only Screen and (max-width: 48em){
    .cities-image {
        background-position-x: 40%;
        margin-bottom: -2rem;
    }

    .cities-table {
        display:flex;
        flex-direction: column;
    }
    .cities-box {
        width: 100vw;
        padding: 2rem 1rem;
    }
    .cities-column {
        align-items: center;
    }
}