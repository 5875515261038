
.brand-page-container {
    display: flex;
    flex-direction: column;
  }
  
  .brand-photo-container {
    position: relative;
    overflow: hidden;
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 40vh;
    margin-top: 3vh;
  }
  
  .brand-photo {
    position: absolute;
    display: block;
    width: 70%; 
  }
  
  .overlay {
    content: "";
    text-align: center;
    padding-top: 15vh;
    position: absolute;
    width: 70%;
    height: 100%;
    color: var(--deep-blue);
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 37%, rgb(255, 255, 255, 0.8) 37%, rgba(255, 255, 255, 0.8) 57%, rgba(255, 255, 255, 0.1) 57%);
  }
  
  .brand-header {
    font-size: 5vh;
    font-weight: 700;
  }
  
  .brand-text {
    align-self: center;
    width: 50%;
    font-size: 1.3rem;
    text-indent: 2rem;
  }
  
  .brand-text p {
    margin-bottom: 1rem;
  }
  
  .brand-text h2 {
    margin-top: 3rem;
  }
  
  .van-photos-brands {
    display: flex;
    justify-content: center;
    column-gap: 10%;
  }
  
  .van-photos-brands img{
    width: 35%;
    border-radius: 1rem;
  }

  .types p{
    text-indent: 2rem;
  }
  
  @media only Screen and (max-width: 48em){
  
  .brand-photo-container {
    position: relative;
    overflow: hidden;
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 35vh;
    margin-top: 0;
  }
  
  .brand-photo {
    position: absolute;
    display: block;
    width: 90%; 
  }
  
  .overlay {
    content: "";
    text-align: center;
    padding-top: 12.5vh;
    position: absolute;
    width: 90%;
    height: 100%;
    color: var(--deep-blue);
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 37%, rgb(255, 255, 255, 0.8) 37%, rgba(255, 255, 255, 0.8) 60%, rgba(255, 255, 255, 0.1) 60%);
  }
  
  .brand-header {
    margin-top: 1rem;
    font-size: 3vh;
    font-weight: 700;
  }
  
  .brand-text {
    width: 80%;
  }
  
  .brand-text h2 {
    text-align:center;
    text-indent: 0;
  }
  
  .van-photos-brands {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
  
  .van-photos-brands img{
    width: 100%;
    border-radius: 1rem;
  }


  }