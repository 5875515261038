.appliance-container {
    display: flex;
    column-gap: 2rem;
    padding: 3rem;
}

.photos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22%;
    margin: 10vh 0 0 0;
    row-gap: 2.5rem;
}

.photos-container img {
    width: 15vw;
    box-shadow: 2px 2px 12px rgb(110, 110, 110);
    border-radius: 1rem;
}

.photos-container-mobile {
    display: none;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.3rem;
    row-gap: 1rem;
    width: 54%;
    
}

.text-container p{
    font-size: 1.3rem;
    text-indent: 30px;
}

.advice-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22%;
    margin-top: 10vh;
    font-size: large;
    /* border: solid 2px black; */
    padding: 2rem 0;
    border-radius: 1rem;
    height:fit-content;
    box-shadow: 2px 2px 10px rgb(110, 110, 110);

}

.advice-container h2{
    width: 80%;
    text-align: center;
    padding-bottom: 1rem;
}

.page-container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.repairs{
    margin-top: 3rem;
}

.repairs h2{
    padding-left: 7rem;
}

@media only Screen and (max-width: 48em){
    .appliance-container {
        display: flex;
        flex-direction: column;
        width: 100vw;
    }

    .text-container {
        width: 100%;  
    }

    .advice-container {
        width: 100%;
    }

    .repairs h2{
        padding-left: 0;
    }

    .photos-container {
        display: none;
    }

    .photos-container-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 10vh 0 0 0;
        row-gap: 3.5rem;
        
    }

    .photos-container-mobile img {
        width: 80vw;
        border-radius: 2rem;
        box-shadow: 2px 2px 12px rgb(110, 110, 110);
    }
}