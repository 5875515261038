#desc-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    align-items: center;
    column-gap: 3rem;
    width: 70%;
    margin: 3rem;
}

#desc-photo {
    width: 50%;
    height: 60%;
}

#desc-header {
    font-size: 2rem;
    text-align: center;
}

#desc-text {
    text-indent: 2rem;
    font-size: 1.3rem;
}

#desc-text a{
    font-weight: bold;
    color: black;
}

#desc-text a:hover{
    color: rgb(17, 0, 255);
}

@media only Screen and (max-width: 48em){
    #desc-container {
        width: 100%;
        align-self: center;
        flex-direction: column;
    }

    #desc-photo {
        width: 80%;
        margin-bottom: 2rem;
    }

    #desc-header {
        font-size: 2rem;
    }

    #desc-text {
        margin-left: 5%;
    }
}
